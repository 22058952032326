import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import AdminHeader from '../../organisms/AdminHeader';
import { ResponsivePXValue, theme } from '../../Theme';
import ChartComponent from '../../organisms/ChartComponent';
import axios from 'axios';
import date from 'date-and-time';
import update from 'react-addons-update';
import PieChart from '../../organisms/PieChartComponent';
import Title from '../../atoms/Title';
import Paragraph from '../../atoms/Paragraph';


const Wrapper = styled.div`
	${ResponsivePXValue('padding-top', { mobile: '45px', tablet: '80px', desktop: '80px' })}
`

const Body = styled.div`
	display: flex;
	flex-direction: column;
	${ResponsivePXValue('padding', { mobile: '10px', tablet: '50px', desktop: '50px' })}
	${ResponsivePXValue('gap', { mobile: '10px', tablet: '50px', desktop: '50px' })}
`

const Charts = styled.div`
	display: flex;
	justify-content: space-between;
	${ResponsivePXValue('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}
	${ResponsivePXValue('gap', { mobile: '10px', tablet: '50px', desktop: '50px' })}
`

const FullChart = styled.div`
	canvas {
		${ResponsivePXValue('padding', { mobile: '10px', tablet: '10px', desktop: '20px' })}
		box-shadow: 0 0 7px #ccc;
		${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '10px', desktop: '10px' })}

	}
`

const Info = styled.div`
	${ResponsivePXValue('padding', { mobile: '10px', tablet: '10px', desktop: '20px' })}
		box-shadow: 0 0 7px #ccc;
		${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '10px', desktop: '10px' })}
`

const Chart = styled.div`
	${ResponsivePXValue('width', { mobile: '100%', tablet: '50%', desktop: '50%' })}
	canvas {
		${ResponsivePXValue('padding', { mobile: '10px', tablet: '10px', desktop: '20px' })}
		box-shadow: 0 0 7px #ccc;
		${ResponsivePXValue('border-radius', { mobile: '10px', tablet: '10px', desktop: '10px' })}

	}
`

const Table = styled.table`
	width: 100%;
	${ResponsivePXValue('display', { mobile: 'block', tablet: 'table', desktop: 'table' })}
    overflow: scroll;
	tbody {
		display: inline-table;
		width: 100%;
	}
	tr {
		&:hover {
				background-color: #ccc;
			}
	}
	td, th {
	border-bottom: 1px solid #ccc;
		${ResponsivePXValue('padding', { mobile: '5px', tablet: '10px', desktop: '10px' })}
	}
`

const Dropdown = styled.select`
  padding: 5px 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

interface ActiveMembers {
	label: string;
	value: number;
}

interface AdminPageProps {
	totalBookings: number;
	currentYear: number;
	selectedYear: number;
	totalActiveMembers: number;
	weeklyActiveBookings: any;
	weeklyCancledBookings: any;
	memberActiveBookings: any;
	memberCancledBookings: any;
	ligaActiveBookings: any;
	ligaCancledBookings: any;
	activeMembers: {
		[key: number]: ActiveMembers;
	}
}

const DEFAULT_STATE: AdminPageProps = {
	totalBookings: 0,
	currentYear: parseInt(date.format(new Date(), "YYYY")),
	selectedYear: parseInt(date.format(new Date(), "YYYY")),
	totalActiveMembers: 0,
	weeklyActiveBookings: new Array(52).fill(0),
	weeklyCancledBookings: new Array(52).fill(0),
	memberActiveBookings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	memberCancledBookings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	ligaActiveBookings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	ligaCancledBookings: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	activeMembers: {}
};

const AdminPage: React.FC = () => {

	const [state, setState] = useState<AdminPageProps>({ ...DEFAULT_STATE });

	const years = Array.from({ length: state.currentYear - 2023 + 1 }, (_, i) => 2023 + i);

	const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newYear = parseInt(event.target.value);

		setState((prevState) =>
			update(prevState, {
				totalBookings: { $set: 0 },
				weeklyActiveBookings: { $set: new Array(52).fill(0) },
				weeklyCancledBookings: { $set: new Array(52).fill(0) },
				memberActiveBookings: { $set: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
				memberCancledBookings: { $set: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
				ligaCancledBookings: { $set: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
				ligaActiveBookings: { $set: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
				activeMembers: { $set: {} },
			})
		);


		setState((prevState) =>
			update(prevState, {
				selectedYear: { $set: newYear },
			})
		);
	};


	function isRepeatedDigits(number: number) {
		if (number) {
			// Convert the number to a string
			const numStr = number.toString();

			// Get the first character
			const firstChar = numStr[0];

			// Check if all characters in the string are the same as the first character
			for (let i = 1; i < numStr.length; i++) {
				if (numStr[i] !== firstChar) {
					return false;
				}
			}
			return true;
		} else {
			return false;
		}
	}

	const getWeekNumber = (date: Date): number => {
		const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
		const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;

		return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
	};

	useEffect(() => {

		axios.get(process.env.REACT_APP_API_URL + 'members').then((response: any) => {
			if (response.data) {
				let totalActiveMembers = state.totalActiveMembers
				console.log('members', response.data)
				for (let i = 0; i < response.data.length; i++) {
					if (response.data[i].active) {
						totalActiveMembers = totalActiveMembers + 1
					}
				}

				setState((prevState) =>
					update(prevState, {
						totalActiveMembers: { $set: totalActiveMembers },
					})
				);
			}
		});

		axios.get(process.env.REACT_APP_API_URL + 'chart/booking-counts?year='+state.selectedYear).then((response: any) => {
			if (response.data) {

				let weeklyActBookings = state.weeklyActiveBookings
				let weeklyCancledBookings = state.weeklyCancledBookings
				let actBookings = state.memberActiveBookings
				let canBookings = state.memberCancledBookings
				let ligaActBookings = state.ligaActiveBookings
				let ligaCanBookings = state.ligaCancledBookings
				let activeMembers = state.activeMembers
				let totalBookings = 0

				for (let i = 0; i < response.data.length; i++) {
					const booking = response.data[i]
					if (booking.deleted_at !== null) {

						const rawMonth = date.format(new Date(booking.date), 'M');
						const weekNum = getWeekNumber(new Date(booking.date));

						if (!weeklyActBookings[weekNum - 1]) {
							weeklyActBookings[weekNum - 1] = 0
						}

						if (isRepeatedDigits(booking.member_number)) {
							ligaCanBookings[(parseInt(rawMonth) - 1)] = ligaCanBookings[parseInt(rawMonth) - 1] + 1
						} else {
							weeklyActBookings[weekNum - 1] = weeklyActBookings[weekNum - 1] + 1
							canBookings[(parseInt(rawMonth) - 1)] = canBookings[parseInt(rawMonth) - 1] + 1
						}



					} else {
						totalBookings = totalBookings + 1
						const rawMonth = date.format(new Date(booking.date), 'M');
						const weekNum = getWeekNumber(new Date(booking.date));

						if (!weeklyCancledBookings[weekNum - 1]) {
							weeklyCancledBookings[weekNum - 1] = 0
						}

						if (isRepeatedDigits(booking.member_number)) {
							ligaActBookings[(parseInt(rawMonth) - 1)] = ligaActBookings[parseInt(rawMonth) - 1] + 1
						} else {
							weeklyCancledBookings[weekNum - 1] = weeklyCancledBookings[weekNum - 1] + 1
							actBookings[(parseInt(rawMonth) - 1)] = actBookings[parseInt(rawMonth) - 1] + 1
						}

						// Active Members 
						if (booking.member_number) {
							if (!activeMembers[booking.member_number]) {
								activeMembers[booking.member_number] = {
									label: booking.first_name + ' ' + booking.last_name,
									value: 0
								}
							}
							activeMembers[booking.member_number].value = activeMembers[booking.member_number].value + 1
						}
						if (booking.oponent_number) {
							if (!activeMembers[booking.oponent_number]) {
								activeMembers[booking.oponent_number] = {
									label: booking.openent_first_name + ' ' + booking.openent_last_name,
									value: 0
								}
							}
							activeMembers[booking.oponent_number].value = activeMembers[booking.oponent_number].value + 1
						}

					}
				}

				const sortedScoresArray = Object.entries(activeMembers)
					.sort(([, a], [, b]) => b.value - a.value)
					.slice(0, 10);

				const topScores: {
					[key: number]: ActiveMembers;
				} = Object.fromEntries(sortedScoresArray);


				setState((prevState) =>
					update(prevState, {
						totalBookings: { $set: totalBookings },
						weeklyActiveBookings: { $set: weeklyActBookings },
						weeklyCancledBookings: { $set: weeklyCancledBookings },
						memberActiveBookings: { $set: actBookings },
						memberCancledBookings: { $set: canBookings },
						ligaCancledBookings: { $set: ligaCanBookings },
						ligaActiveBookings: { $set: ligaActBookings },
						activeMembers: { $set: topScores },
					})
				);
			}
		});


	}, [state.selectedYear]);

	return (<Wrapper>

		<AdminHeader />
		<Body>

			<Dropdown id="year-select" value={state.selectedYear} onChange={handleChange}>
				{years.map((year) => (
					<option key={year} value={year}>
						{year}
					</option>
				))}
			</Dropdown>

			<Charts>

				<Chart>
					<Info>
						<Title variant="t1" color={theme.colors.black.pureBlack}>{state.selectedYear}</Title><br></br>
						<Table width="100%" className="sortable">
							<tbody>
								<tr>
									<td><Paragraph color='#000'><strong>Bookings:</strong></Paragraph></td>
									<td><Paragraph color='#000'>{state.totalBookings}</Paragraph></td>
								</tr>
								<tr>
									<td><Paragraph color='#000'><strong>Bookings Income (R25):</strong></Paragraph></td>
									<td><Paragraph color='#000'>R{state.totalBookings * 25}</Paragraph></td>
								</tr>
								<tr>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td><Paragraph color='#000'><strong>Active Members:</strong></Paragraph></td>
									<td><Paragraph color='#000'>{state.totalActiveMembers}</Paragraph></td>
								</tr>
								<tr>
									<td><Paragraph color='#000'><strong>Members Income (R1150):</strong></Paragraph></td>
									<td><Paragraph color='#000'>R{state.totalActiveMembers * 1150}</Paragraph></td>
								</tr>
								<tr>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td><Paragraph color='#000'><strong>Total Income:</strong></Paragraph></td>
									<td><Paragraph color='#000'>R{(state.totalBookings * 25) + (state.totalActiveMembers * 1150)}</Paragraph></td>
								</tr>
							</tbody>
						</Table>
					</Info>
				</Chart>
				<Chart>
					<PieChart data={state.activeMembers} />
				</Chart>
			</Charts>


			<Charts>
				<Chart>
					<ChartComponent title="Members bookings"
						labels={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
						data={[
							{
								label: 'Active',
								data: state.memberActiveBookings,
								fill: false,
								backgroundColor: 'green',
								borderColor: 'green',
							},
							{
								label: 'cancelled',
								data: state.memberCancledBookings,
								fill: false,
								backgroundColor: 'red',
								borderColor: 'red',
							},
						]} />
				</Chart>
				<Chart>

					<ChartComponent title="Liga bookings"
						labels={['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']}
						data={[
							{
								label: 'Active',
								data: state.ligaActiveBookings,
								fill: false,
								backgroundColor: 'green',
								borderColor: 'green',
							},
							{
								label: 'cancelled',
								data: state.ligaCancledBookings,
								fill: false,
								backgroundColor: 'red',
								borderColor: 'red',
							},
						]} />
				</Chart>
			</Charts>

			<FullChart>
				<ChartComponent title="Weekly bookings"
					labels={[
						"Jan", 2, 3, 4, "Feb", 6, 7, 8,
						"Mar", 10, 11, 12, 13, "Apr", 15, 16, 17,
						"May", 19, 20, 21, "Jun", 23, 24, 25, 26,
						"Jul", 28, 29, 30, "Aug", 32, 33, 34, 35,
						"Sep", 37, 38, 39, "Oct", 41, 42, 43, 44,
						"Nov", 46, 47, 48, "Dec", 50, 51, 52
					]}
					data={[
						{
							label: 'Active',
							data: state.weeklyCancledBookings,
							fill: false,
							backgroundColor: 'green',
							borderColor: 'green',
						},
						{
							label: 'cancelled',
							data: state.weeklyActiveBookings,
							fill: false,
							backgroundColor: 'red',
							borderColor: 'red',
						},
					]} />
			</FullChart>
		</Body>

	</Wrapper>);
};

export default AdminPage;
